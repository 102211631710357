import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Products from '../components/Products/products'

const Store = (props) => (
  <Layout location={props.location} title="Store Checkout">
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Products skuClassName="col-12 col-lg-2  sku-product" />
  </Layout >
)

export default Store