import React from 'react'
import { toast } from 'react-toastify';
import { toastSettings } from "../../utils/constants";
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import styled from "styled-components";
import classNames from "classnames";

const SkuContainer = styled.div`
 padding:5px;

 .sku-inner-container{
   border:1px solid black;
   padding:5px;
   padding-bottom:0;
   position:relative;
   font-size:14px;
   height:100%;
   justify-content:center;

    img{
      max-height:240px;
      width:100%;
      padding:0;
      vertical-align:top;

    }
   .product-add-button{
     bottom:10px;
     position:absolute;
   }
 }
`;
const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
  paddingBottom: '10px',
  backgroundColor: '#fff',
  borderRadius: '6px',
  minHeight: '530px'
}

interface Sku {
  name: string,
  price: string,
  description: string,
  currency: string,
  image: string,
}

interface SkuProps {
  sku: Sku,
  className?: string,
  showToast: (text: any) => void;
}

const SkuCard = (props: SkuProps) => {
  const { sku, className } = props;
  const { addItem } = useShoppingCart()

  const handleProductClick = (sku) => {
    addItem(sku);
    props.showToast('🦄 Your item has been added.');
  }

  return (
    <SkuContainer style={cardStyles} className={classNames(className)}>
      <div className="mx-1 sku-inner-container">
        <h6>{sku.name}</h6>
        <p><strong>
          Price:{' '}
          {formatCurrencyString({
            value: parseInt(sku.price, 10),
            currency: sku.currency,
          })}
        </strong>
        </p>
        <img src={sku.image} />
        <p>{sku.description}</p>
        <button className="product-add-button" onClick={() => handleProductClick(sku)}>
          Add To Cart
      </button>
      </div>
    </SkuContainer>
  )
}

export default SkuCard