import React from "react";
import { graphql, StaticQuery } from "gatsby";
import SkuCard from "./skuCard";
import { toastSettings } from '../../utils/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Products(props) {
  const { skuClassName } = props;
  const showToast = (text) => {
    toast.success(text, toastSettings);
  }

  return (
    <StaticQuery
      query={graphql`
        query ProductPrices {
          prices: allStripePrice(
            filter: {product: {metadata: {id: {}}}, active: {eq: true}}
            sort: {fields: product___metadata___id}
          ) {
            edges {
              node {
                product {
                  active
                  metadata {
                    id
                  }
                  description
                  images
                  name
                }
                id
                currency
                active
                unit_amount
              }
            }
          }
        }
      `}
      render={({ prices }) => {
        let skus;
        {
          skus = prices.edges.map(({ node: price }) => {
            const newSku = {
              sku: price.id,
              name: price.product.name,
              price: price.unit_amount,
              currency: price.currency,
              image: price.product.images,
              description: price.product.description
            }
            return <SkuCard className={skuClassName} key={price.id} sku={newSku} showToast={showToast} />
          })
        }
        return (<div className="row px-0 mx-0">
          {skus}
          <ToastContainer />
        </div>)

      }}
    />
  )
}